import {useLanguage} from "@/contexts/LanguageContext";
import {useEffect, useState} from "react";

export interface IStaticText {
	nav1: string;
	nav2: string;
	nav3: string;
	nav4: string;
	nav5: string;
	nav6: string;
	nav7: string;
	page1: string;
	page2: string;
	page3: string;
	page4: string;
	page5: string;
	page6: string;
	page7: string;
	page8: string;
	page9: string;
	page10: string;
	page11: string;
	page12: string;
	page13: string;
	page14: string;
	page15: string;
	page16: string;
	page17: string;
	page18: string;
	page19: string;
	page20: string;
	page21: string,
	page22: string,
	page23: string,
	page24: string,
	page25: string,
	page26: string,
	page27: string,
	page28: string,
	page29: string,
	page30: string,
	page31: string,
	page32: string,
	page33: string,
	page34: string,
	page35: string,
	page36: string,
	page37: string,
	page38: string,
	page39: string,
	page40: string,
	page41: string,
	page42: string,
	page43: string,
	page44: string,
	page45: string,
	page46: string,
	page47: string,
	page48: string,
	page49: string,
	page50: string,
	page51: string,
	page52: string,
	page53: string,
	page54: string,
	page55: string,
	page56: string
}

const staticContent: {
	en: IStaticText;
	zh: IStaticText;
	[x: string]: IStaticText;
} = {
	en: {
		nav1: "Home",
		nav2: "Performance Advantages",
		nav3: "Technical Features",
		nav4: "Governance Projects",
		nav5: "Ecological Facilities",
		nav6: "Wallet Download",
		nav7: "Block Explorer",
		page1: "Explore the DKP Chain Ecosystem",
		page2: "Next-Generation Decentralized Infrastructure",
		page3: "Whitepaper",
		page4: "Next Generation",
		page5: "Internet's",
		page6: "Cornerstone",
		page7: "DKP Public Chain adopts advanced technologies and mechanisms in transaction processing and verification to ensure efficient, secure, and transparent transactions.",
		page8: "The professional network architecture is designed to achieve efficient data processing and excellent scalability while maintaining decentralization and security. The design of the network structure considers various factors, including node distribution, data transmission efficiency, and fault tolerance mechanisms. The blockchain ecosystem provides application developers with a complete set of infrastructure tools, supporting advanced scalability and interoperability to help developers easily access the entire blockchain ecosystem.",
		page9: "Scalability",
		page10: "DKP Public Chain adopts a layered architecture design to address a wide range of transaction processing needs, attempting to introduce non-monetary side chains to shift some of the load and ensure efficiency.",
		page11: "Continuous Upgrades",
		page12: "DKP Public Chain undergoes regular technical upgrades, continually introducing new features and optimizing existing mechanisms.",
		page13: "Data Storage Mechanism",
		page14: "DKP Public Chain adopts a distributed data storage mechanism, dispersing data storage across multiple nodes in the network.",
		page15: "Smart Contracts",
		page16: "DKP Public Chain provides a comprehensive smart contract development framework, including contract programming languages, development tools, and testing environments.",
		page17: "Privacy Protection Mechanism",
		page18: "DKP Public Chain places high importance on user privacy protection, adopting various mechanisms to ensure the security of user privacy.",
		page19: "Cross-Chain Interoperability",
		page20: "Cross-chain technology enables DKP Public Chain to interact and exchange data with other blockchain systems.",
		page21: "Integrated",
		page22: "Speed, Privacy, Scalability, and Liquidity",
		page23: "Hundreds of Times Faster than Ethereum",
		page24: "Average Block Size",
		page25: "Block Interval Time",
		page26: "Validator Nodes",
		page27: "Our Features",
		page28: "Create and Promote the Development of Public Chain Infrastructure",
		page29: "The development of the DKP Public Chain is part of the continuous innovation and progress of blockchain technology. Its technical architecture, consensus mechanism, and ecosystem design demonstrate significant innovation and potential. The DKP Public Chain will continue to promote the popularity and application of blockchain technology, creating more value for users and society.",
		page30: "Advanced Consensus Mechanism",
		page31: "Adopts new hybrid consensus algorithms like POSA upgraded TPOS, effectively balancing security and efficiency, providing higher transaction processing speed, and network stability.",
		page32: "Flexible Technical Architecture",
		page33: "The application of layered architecture and side chain technology enhances system scalability and adaptability, enabling the DKP Public Chain to support a wide range of application scenarios.",
		page34: "Rich Ecosystem",
		page35: "Diversified decentralized application ecosystem construction provides users with rich choices and innovative experiences. The ecosystem will help blockchain technology enter more traditional fields and drive industry value development.",
		page36: "Total Issuance and Distribution of DKP",
		page37: "The main functions of DKP tokens include paying network transaction fees, participating in network governance, incentivizing ecosystem participants,",
		page38: "and serving as interaction credentials for various applications and services. These functions ensure the efficient operation of the DKP Public Chain and the healthy development of the ecosystem.",
		page39: "70% Mined through POS Staking, Producing 21 Million Tokens",
		page40: "10% Issued as Rewards for Early Project Participants, 3 Million Tokens",
		page41: "10% Released in the Ecology Sector, 3 Million Tokens",
		page42: "10% Held by the Foundation, 3 Million Tokens",
		page43: "Risk Warning and Disclaimer",
		page44: "DKPChain is a public, open, non-profit system, and the internal reward and operation maintenance mechanisms all use virtual digital asset certificates, not a currency reward mechanism. The digital assets generated by the system itself can be used as rewards for system maintenance. To meet the resource exchange needs between the system and other systems or other social entities, ecological application developers, based on project needs and compliance with local laws and regulations, can introduce other digital assets through SWAP exchanges.",
		page45: "DKPChain's Powerful Ecosystem",
		page46: "Enhance community participation and governance, establish a more open and collaborative ecosystem",
		page47: "Blockchain Explorer",
		page48: "SWAP Exchange",
		page49: "Cross-Chain Bridge",
		page50: "Open API",
		page51: "Decentralized Wallet",
		page52: "Decentralized Governance",
		page53: "Decentralized Wallet, Ultimate Security Experience",
		page54: "Orderly Multichain Management for Your DKPChain and Other Digital Assets",
		page55: "Contact Us",
		page56: "Email"
	},
	zh: {
		nav1: "首頁",
		nav2: '性能優勢',
		nav3: "技術特色",
		nav4: "治理專案",
		nav5: "生態設施",
		nav6: "錢包下載",
		nav7: "區塊瀏覽器",
		page1: "探索 DKP Chain 生態系統",
		page2: "全新去中心化基礎設施",
		page3: "白皮書",
		page4: "下一代",
		page5: "互聯網的",
		page6: "基石",
		page7: "DKP公鏈在交易處理和驗證方面採用了先進的技術和機制，以確保交易的高效、安全和透明。",
		page8: "專業的網絡結構設計旨在實現高效的數據處理和優秀的擴展性，同時保持網絡的去中心化和安全性。網絡結構的設計考慮了多種因素，包括節點分佈、數據傳輸效率和容錯機制等。區塊鏈生態為應用開發者提供全套基礎設施工具，更支持先進的擴展和互操作性，幫助開發者輕鬆觸達整個區塊鏈生態系統。",
		page9: "可擴展性",
		page10: "DKP公鏈採用了分層架構設計來應對廣泛的交易需求處理，嘗試引入無幣化側鏈將部分負載轉移保障效率",
		page11: "持續升級",
		page12: "DKP公鏈通過定期的技術升級，不斷引入新的功能和優化現有的機制",
		page13: "數據存儲機制",
		page14: "DKP公鏈採用了分佈式數據存儲機制，將數據分散存儲在網絡中的多個節點上",
		page15: "智能合約",
		page16: "DKP公鏈提供了一套完整的智能合約開發框架，包括合約編程語言、開發工具和測試環境",
		page17: "隱私保護機制",
		page18: "DKP公鏈高度重視用戶的隱私保護，採用了多種機制來確保用戶隱私的安全",
		page19: "跨鏈互操作",
		page20: "跨鏈技術使得DKP公鏈能夠與其他區塊鏈系統進行互操作和數據交換",
		page21: "集於一體",
		page22: "速度、隱私、擴展性與流動性",
		page23: "快Ethereum數百倍",
		page24: "平均區塊大小",
		page25: "出塊間隔時間",
		page26: "驗證人節點",
		page27: "我們的特色",
		page28: "創建並推動公鏈設施發展",
		page29: "DKP公鏈的發展是區塊鏈技術不斷創新和進步的一部分，其技術架構、共識機制和生態系統設計展現了顯著的創新和潛力。DKP公鏈將繼續推動區塊鏈技術的普及和應用，為用戶和社會創造更多的價值。",
		page30: "先進的共識機制",
		page31: "採用POSA升級TPOS等新型混合共識算法，有效平衡了安全性和效率，提供了更高的交易處理速度和網絡穩定性。",
		page32: "靈活的技術架構",
		page33: "分層架構和側鏈技術的應用，提高了系統的可擴展性和適應性，使得DKP公鏈能夠支持廣泛的應用場景。",
		page34: "豐富的生態系統",
		page35: "多元化的去中心化應用生態建設，為用戶提供豐富的選擇和創新體驗，生態系統將幫助區塊鏈技術進入更多傳統領域推動行業價值發展。",
		page36: "DKP總發行量與分配",
		page37: "DKP代幣的主要功能包括支付網絡交易費、參與網絡治理、激勵生態系統參與者，",
		page38: "以及作為各種應用和服務的交互憑證。這些功能確保了DKP公鏈的高效運行和生態系統的健康發展",
		page39: "70% 通過POS質押挖礦產出2100萬枚",
		page40: "10% 作為項目早期參與者的獎勵憑證發行300萬枚",
		page41: "10% 生態板塊釋放300萬枚",
		page42: "10% 基金會持有300萬枚",
		page43: "風險提示及免責聲明",
		page44: "DKPChain是公益的、開放的、非營利系統，系統內部的獎勵機制、運營維護機制均採用虛擬數字資產憑證，而非採用貨幣獎勵機制。系統自身產生的數字資產，可以作為系統維護的獎勵。為滿足系統與其他系統或其他社會主體的資源交換需求，在遵守應用服務所在地區法律法規前提下，生態應用開發者基於項目需要可通過SWAP交換適當引入其他數字資產。",
		page45: "DKPChain的強大生態",
		page46: "增強社區參與和治理，建立更加開放和協作的生態系統",
		page47: "區塊鏈瀏覽器",
		page48: "SWAP交換",
		page49: "跨鏈橋",
		page50: "開放API",
		page51: "去中心化錢包",
		page52: "去中心化治理",
		page53: "去中心化錢包，極致安全體驗",
		page54: "從此有序多鏈管理，您的DKPChain等數字資產",
		page55: "联系我们",
		page56: "电子邮箱",
	},
};

export function useStaticContent() {
	const {language} = useLanguage();
	const [data, setData] = useState<IStaticText>(staticContent[language]);
	useEffect(() => {
		setData(staticContent[language]);
	}, [language, setData]);

	return data;
}
